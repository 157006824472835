export const UbisoftIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 32 32"
    fill="currentColor"
  >
    <path d="M31.416 15.984C31.068-.407 9.271-6.521.875 8.844c.376.276.88.635 1.256.896a15.72 15.72 0 0 0-1.303 4.093 15.857 15.857 0 0 0-.244 2.76C.584 25.093 7.495 32 16.005 32c8.516 0 15.411-6.896 15.411-15.407zM4.385 18.729c-.203 1.667-.073 2.183-.073 2.385l-.375.131c-.14-.272-.489-1.24-.651-2.543-.407-4.957 2.979-9.421 8.14-10.265 4.724-.692 9.251 2.245 10.303 6.349l-.375.131c-.115-.115-.303-.448-1.027-1.172-5.708-5.709-14.672-3.095-15.943 4.989zm14.672 2.776a4.189 4.189 0 0 1-3.453 1.807 4.197 4.197 0 0 1-4.208-4.208 4.214 4.214 0 0 1 3.901-4.187c1.359-.057 2.629.676 3.224 1.864a3.438 3.438 0 0 1-.604 3.927c.389.276.765.537 1.14.797zm8.771.162c-2.224 5.041-6.807 7.688-11.692 7.615-9.381-.464-12.109-11.287-5.839-15.188l.276.271c-.104.147-.48.439-1.057 1.579-.677 1.385-.896 2.776-.808 3.641.489 7.561 11.089 9.109 14.729 1.619C28.078 10.96 15.76.537 4.833 8.501l-.245-.245c2.876-4.509 8.5-6.52 13.86-5.176 8.197 2.067 12.604 10.609 9.38 18.588z" />
  </svg>
);
